<template>
  <section>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="vc">
          <h1 :class="[isMobile ? '' : 'ccheadline', 'mb-2']">
            {{ title || "Get started now" }}
          </h1>
          <p class="f125">Register your business in 2 minutes</p>

          <Signup class="mt-12"></Signup>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          :class="isMobile ? 'mt-15 text-right' : 'text-right'"
        >
          <img
            :src="img"
            height="300px"
            class="img-border-radius"
            alt="Growcify eCommerce App"
          />
          <!-- <iframe
            style="margin-bottom: -25px; border-radius: 10px"
            width="100%"
            height="300px"
            :src="`https://www.youtube.com/embed/q2dJcHoCugM?rel=0`"
            title="Growcify Ad Film"
            frameborder="0"
            allow="picture-in-picture"
            allowfullscreen
          ></iframe> -->
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {
  name: "GetStarted",

  props: ["title"],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },

    domain() {
      return this.$store.state.domain;
    },
  },

  data() {
    return {
      img: require("@/assets/imgs/growcify-default-banner-2.png"),
    };
  },
};
</script>
