<template>
  <section>
    <v-container style="background-color: antiquewhite; border-radius: 15px">
      <v-row>
        <v-col cols="12" sm="6" :class="isMobile ? 'vc' : 'vc pl-10'">
          <h3 :class="['mb-2', isDT ? 'text-black' : '']">Introducing,</h3>
          <h2 :class="['mb-2', isDT ? 'text-black' : '']">
            <b>Growcify Independence Day Plan</b>
          </h2>
          <p :class="['f125', isDT ? 'text-black' : '']">
            Get your own <u>eCommerce Android app</u> within 24 hours with our
            exclusive onetime Independence Day Plan!
          </p>

          <p>
            <b>Price: INR 15,000 + GST 18%</b>
          </p>
          <small> AMC Applicable from 2nd year onwards. T&C Apply. </small>

          <v-card
            outlined
            link
            :class="['pt-35 pb-35 pl-4 pr-4 text-center signup']"
            :color="!isDT ? 'white' : 'black'"
            :style="
              isMobile ? 'margin-top: 20px' : 'width: 52.5%; margin-top: 40px'
            "
            @click="scheduleCall"
          >
            <b>Schedule Demo Now</b>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          :class="isMobile ? 'mt-15 text-right' : 'text-right pr-0'"
        >
          <img :src="img1" alt="growcify-offer" class="screen-img rounded" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import config from "../../config";

export default {
  name: "Prebooking",

  props: ["title"],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },

    domain() {
      return this.$store.state.domain;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    scheduleCall() {
      window.Calendly.initPopupWidget({ url: config.calendlyURL });
      return false;
    },
  },

  data() {
    return {
      img: require("@/assets/imgs/growcify-default-banner-2.png"),

      img1: require("@/assets/imgs/offer-banner.png"),
    };
  },
};
</script>
