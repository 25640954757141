import $ from 'jquery';
import GetStarted from '../../components/get-started/get-started.vue';

export default {
  name: 'POS',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  components: {
    GetStarted
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  created() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  data() {
    return {
      imgs: {
        i1: require('@/assets/imgs/growcify-pos-banner.png'),
        i2: require('@/assets/imgs/growcify-pos-2-banner.png'),
        i3: require('@/assets/imgs/growcify-pos-payment-link-banner.png'),
        i4: require('@/assets/imgs/growcify-pos-barcode-banner.png'),
        i5: require('@/assets/imgs/growcify-pos-history-banner.png'),
      },

      pitems: [
        'Synchronize inventory across all platforms',
        'Multi-channel inventory management',
        'Keep track of your history and export reports',
        'Integrate barcode scanner & printer',
        'Track inventory and more'
      ],

      meta: {
        title: 'Growcify - Point of Sale (POS)',
        description: 'Growcify is a fully-featured ePOS & eCommerce platform that comes with an integrated point of sale system to manage offline eCommerce billing & inventory.'
      },
    }
  }
}