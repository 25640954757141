import $ from "jquery";

export default {
  name: "Pricing",

  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        { name: "description", content: this.meta.description },
        { property: "og:title", content: this.meta.title },
        { property: "og:description", content: this.meta.description },
      ],
    };
  },

  created() {
    if (this.$store.state.pricing) this.pricing = this.$store.state.pricing;
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  computed: {
    pfetching() {
      return this.$store.state.pfetching;
    },

    currencyCode() {
      return this.currency || this.domain ? "USD" : "INR";
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    darkDullColor() {
      return this.isDT ? "grey darken-3" : "grey lighten-3";
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    domain() {
      return this.$store.state.domain;
    },
  },

  methods: {
    items(plan) {
      if (plan == "growcify-basic") return this.bitems;
      if (plan == "growcify-premium") return this.pitems;
      if (plan == "growcify-enterprise") return this.eitems;
    },

    replacedFee(string) {
      return string.replace(
        "{{fee}}",
        this.pricing.payg[this.currencyCode].fee
      );
    },

    navigate(name) {
      return this.$router.push({ name: name }).catch(() => {});
    },
  },

  watch: {
    "$store.state.pricing"(n) {
      if (n) this.pricing = n;
    },

    mode(n, o) {
      if (!n && n != 0) this.mode = o;
    },
  },

  data() {
    return {
      mode: 0,
      currency: 0,
      pricing: {},

      bitems: [
        "0% Growcify Platform fee*",
        "Your single-vendor Android app",
        "Your single-vendor iOS app",
        "Point of Sale (POS)",
        "1000 free SMS credits",
        "5 GB media storage space",
        "Unlimited orders",
        "Unlimited products",
        "Unlimited categories",
        "Unlimited customers",
        "Unlimited downloads",
        "Push notifications",
        "24/7 system and app up",
        "Abandoned cart",
        "Payment gateway integration",
        "Shipping & logistics integration",
        "Sales & Inventory Dashboard",
        "Up to 10 employees access",
        "Get your app within 24 hours",
        "Office hours support (9am - 6pm)",
        "Google Play & Apple App Store Listing",
      ],

      pitems: [
        "Everything in basic plan",
        "Your single or multi-vendor website",
        "Your single or multi-vendor app",
        "Up to 10 different vendors",
        "Point of Sale (up to 10 licenses)",
        "Multilingual support",
        "Pickup & Drop feature",
        "10 GB media storage space",
        "Customer pricing contracts",
        "Up to 25 employees access",
        "Create and manage product subscriptions",
        "Combo products and cashback coupons",
      ],

      eitems: [
        "Everything in premium plan",
        "Your brand's SMS Sender ID*",
        "Your brand's email communication",
        "Unlimited media storage space",
        "Unlimited employees access",
        "Unlimited POS licenses",
        "Unlimited vendors (multi-vendor)",
        "Custom domain support for dashboard",
        "Free upgrade to all new features in app",
        "Dedicated account manager",
        "Account setup support",
        "Call/WhatsApp support",
        "24/7 priority email support",
      ],

      paitems: [
        "All features are included",
        "Pay {{fee}}% / per-order as you grow",
        "Working hours email support available",
        "Access to the Growcify platform (Single Vendor)",
        "Get your Android App, iOS App, Website and POS",
      ],

      meta: {
        title: "Growcify - Pricing",
        description:
          "Our eCommerce website development cost ranges from basic to premium and enterprise subscription based on your business needs or you can pay as you grow!",
      },
    };
  },
};
