<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" class="vc text-center">
        <a
          href="https://www.hindustantimes.com/brand-stories/unlock-your-ecommerce-potential-with-growcify-over-inr-100-cr-in-sales-and-counting-101716289535108.html"
          target="_blank"
        >
          <img
            :src="ht"
            alt="Growcify in Hindustan Times"
            cover
            style="width: 250px"
            class="blacknwhite"
          />
        </a>
      </v-col>
      <v-col cols="12" sm="4" class="vc text-center">
        <a
          href="https://www.freepressjournal.in/business/from-zero-to-inr-100cr-how-growcify-transforms-businesses-into-ecommerce-giants"
          target="_blank"
        >
          <img
            :src="fp"
            alt="Growcify in Free Press Journal"
            cover
            style="width: 250px"
            class="img-border-radius blacknwhite"
          />
        </a>
      </v-col>
      <v-col cols="12" sm="4" class="vc text-center">
        <a
          href="https://ians.in/vmpl-detail/unlock-inr-100cr-potential-growcifys-ecommerce-solutions-propel-business-growthfounders-meghraj-suthar-shivam-mishra"
          target="_blank"
        >
          <img
            :src="ians"
            class="blacknwhite"
            alt="Growcify in IANS"
            cover
            style="width: 250px"
          />
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NewsArticles",

  data() {
    return {
      ht: require("@/assets/imgs/hindustan-times.png"),
      fp: require("@/assets/imgs/free-press-journal.png"),
      ians: require("@/assets/imgs/ians.png"),
    };
  },
};
</script>
