import config from '../../config';
import Misc from '../../services/misc';

export default {
  name: 'ContactForm',

  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;

      const lead = {
        name: this.contact.name,
        email: this.contact.email,
        city: this.contact.city,
        remarks: 'Message: '+this.contact.message,
        contactNumber: '+'+this.$store.state.callingCode+this.$store.state.mobile,
      };

      this.processing = true;
      return Misc.saveLead(lead)
        .then(() => {
          //console.log(x.data);
          this.submitted = true;
          this.processing = false;
        })
        .catch(e => {
          console.log(e);
          this.processing = false;
        });
    },
  },

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },
  },

  data() {
    const emailregex = /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;
    return {

      contact: {},
      submitted: false,
      processing: false,

      checkmarkAnimationJson: config.checkmarkAnimationJson,

      validationRules: {
        basicRule: [
          v => !!v || 'It is required'
        ],
        mobileRule: [
          v => (!!v && !isNaN(v)) || 'Enter a valid mobile number'
        ],
        emailRule: [
          v => !!v && emailregex.test(v) || 'Must be a valid email'
        ]
      },

    }
  }
}