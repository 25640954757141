import $ from "jquery";

import config from "../../config";
import Misc from "../../services/misc";

export default {
  name: "Contact",

  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        { name: "description", content: this.meta.description },
        { property: "og:title", content: this.meta.title },
        { property: "og:description", content: this.meta.description },
      ],
    };
  },

  created() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  methods: {
    scheduleCall() {
      window.Calendly.initPopupWidget({ url: config.calendlyURL });
      return false;
    },

    call(input) {
      window.open(`tel:${input}`, "_self");
    },

    email(input) {
      window.open(`mailto:${input}`, "_self");
    },

    navigate(url) {
      this.$router.push({ name: url }).catch(() => {});
    },

    submit() {
      if (!this.$refs.form.validate()) return;

      const lead = {
        name: this.contact.name,
        email: this.contact.email,
        city: this.contact.city,
        remarks: "Message: " + this.contact.message,
        contactNumber:
          "+" + this.$store.state.callingCode + this.$store.state.mobile,
      };

      this.processing = true;
      return Misc.saveLead(lead)
        .then(() => {
          //console.log(x.data);
          this.submitted = true;
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
        });
    },

    social(type) {
      let url = "";
      if (type == "facebook") url = config.facebookURL;
      if (type == "instagram") url = config.instagramURL;
      if (type == "twitter") url = config.twitterURL;
      if (type == "linkedin") url = config.linkedinURL;
      if (type == "youtube") url = config.youtubeURL;

      window.open(url, "_blank");
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    getNumbers() {
      let numbers = this.contactNumbers.default;
      if (this.domain) {
        if (this.contactNumbers[this.domain]) {
          numbers = this.contactNumbers[this.domain];
        }
      }

      return numbers;
    },

    domain() {
      return this.$store.state.domain;
    },

    getAddress() {
      let address = this.addresses.default;
      if (this.domain) {
        if (this.addresses[this.domain]) {
          address = this.addresses[this.domain];
        }
      }

      return address;
    },
  },

  data() {
    const emailregex =
      /^[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i;

    return {
      img: require("@/assets/imgs/growcify-contact.png"),

      contact: {},
      submitted: false,
      processing: false,

      checkmarkAnimationJson: config.checkmarkAnimationJson,

      validationRules: {
        basicRule: [(v) => !!v || "It is required"],
        mobileRule: [
          (v) => (!!v && !isNaN(v)) || "Enter a valid mobile number",
        ],
        emailRule: [
          (v) => (!!v && emailregex.test(v)) || "Must be a valid email",
        ],
      },

      contactNumbers: {
        default: ["+91 8005771838", "+91 9256277838"],
        ca: ["+1 (437) 973-1584"],
      },

      addresses: {
        default:
          "iStart Incubation Centre, Vikramaditya Nagar, Surya Colony, Jodhpur, Rajasthan, India - 342001.",
      },

      meta: {
        title: "Growcify - Contact",
        description:
          "Want to go online with Growcify? Schedule a demo call or request a callback by filling out the form, so then our support will get in touch with you soon.",
      },
    };
  },
};
