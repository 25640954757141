import $ from 'jquery';
import config from '../../config';

import GetStarted from '../../components/get-started/get-started.vue';

export default {
  name: 'Website',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  components: {
    GetStarted
  },

  mounted() {
    this.aimg = this.imgs.i1;
    
    const array = [
      this.imgs.i1,
      this.imgs.i2,
      this.imgs.i3,
      this.imgs.i4,
      this.imgs.i5,
      this.imgs.i6,
      this.imgs.i7,
    ];
    setInterval(() => {
      setTimeout(() => {
        this.aimg = array[Math.floor(Math.random() * array.length)];
      }, 500);
    }, 5000);
  },

  created() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  methods: {
    open(type) {
      let url = '';
      if (type == 'web') url = config.webURL; 
      window.open(url, '_system');
    },

    scheduleCall() {
      window.Calendly.initPopupWidget({url: config.calendlyURL});
      return false;
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      aimg: '',
      imgs: {
        i1: require('@/assets/imgs/growcify-website-1-banner.png'),
        i2: require('@/assets/imgs/growcify-website-2-banner.png'),
        i3: require('@/assets/imgs/growcify-website-3-banner.png'),
        i4: require('@/assets/imgs/growcify-website-4-banner.png'),
        i5: require('@/assets/imgs/growcify-website-5-banner.png'),
        i6: require('@/assets/imgs/growcify-website-6-banner.png'),
        i7: require('@/assets/imgs/growcify-website-7-banner.png'),
        i8: require('@/assets/imgs/growcify-website-8-banner.png'),
        i9: require('@/assets/imgs/growcify-website-9-banner.png'),

        i10: require('@/assets/imgs/growcify-website-10-banner.png'),
        i11: require('@/assets/imgs/growcify-website-11-banner.png'),
        i12: require('@/assets/imgs/growcify-website-12-banner.png'),
        i13: require('@/assets/imgs/growcify-website-13-banner.png'),
        i14: require('@/assets/imgs/growcify-website-14-banner.png'),
        i15: require('@/assets/imgs/growcify-website-15-banner.png'),
        i16: require('@/assets/imgs/growcify-business-owner.png'),
      },

      meta: {
        title: 'Create and launch your eCommerce website - Growcify',
        description: 'Go online with an eCommerce d2c website with the fastest browsing experience and amazing countless features like wishlists, subscriptions, and many more.'
      },
    }
  }
}