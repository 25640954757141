import Vue from "vue";
import $ from "jquery";

import Misc from "./services/misc";

import Header from "./components/header/header.vue";
import Footer from "./components/footer/footer.vue";

import Home from "./pages/home/home.vue";
import POS from "./pages/pos/pos.vue";
import AppPage from "./pages/app/app.vue";
import AppV2Page from "./pages/app-v2/app-v2.vue";
import Contact from "./pages/contact/contact.vue";
import Website from "./pages/website/website.vue";
import Pricing from "./pages/pricing/pricing.vue";
import Resources from "./pages/resources/resources.vue";
import Dashboard from "./pages/dashboard/dashboard.vue";
import PageNotFound from "./pages/page-not-found/page-not-found.vue";

import GetStartedI from "./pages/get-started-i/get-started-i.vue";
import GetStartedII from "./pages/get-started-ii/get-started-ii.vue";

import PrivacyPolicy from "./pages/privacy-policy/privacy-policy.vue";
import TermsAndConditions from "./pages/terms-and-conditions/terms-and-conditions.vue";
import { getDomain } from "./util/misc";

Vue.component(
  "Countries",
  require("./components/countries/countries.vue").default
);
Vue.component("Signup", require("./components/signup/signup.vue").default);

export default {
  name: "App",

  components: {
    Header,
    Footer,
    Home,
    POS,
    AppPage,
    AppV2Page,
    Website,
    Dashboard,
    Pricing,
    Contact,
    Resources,
    PrivacyPolicy,
    TermsAndConditions,
    GetStartedI,
    GetStartedII,
    PageNotFound,
  },

  computed: {
    routeName() {
      return this.$route.name;
    },

    hideHF() {
      return ["get-started", "get-started-ii", "get-started-iii"].includes(
        this.routeName
      );
    },

    isLoaded() {
      return this.$store.state.loaded;
    },
  },

  beforeMount() {
    const newThis = this;
    $(window).on("resize", function () {
      var win = $(this); //this = window
      if (win.width() <= 750) newThis.$store.state.isMobile = true;
      else newThis.$store.state.isMobile = false;
    });

    if ($(window).width() <= 750) {
      this.$store.state.isMobile = true;
    }
  },

  mounted() {
    const theme = localStorage.getItem("dark");
    if (theme) {
      if (theme == "true") this.$vuetify.theme.dark = true;
      else this.$vuetify.theme.dark = false;
    }

    // if (
    //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //     navigator.userAgent
    //   )
    // ) {
    //   this.$store.state.isMobile = true;
    // } else {
    //   this.$store.state.isMobile = false;
    // }
  },

  created() {
    this.processCountrySettings();
    this.processCountrySelection();
    this.fetchPricing();
    this.fetchResources();
  },

  methods: {
    processCountrySettings() {
      const domain = getDomain();
      if (!["growcify.com", "growcify.in", "localhost"].includes(domain)) {
        const ext = domain.split(".")[1];
        this.$store.state.domain = ext;
        this.$store.state.callingCode = "1";
      }
    },

    processCountrySelection() {
      let newthis = this;
      window.onload = () => {
        const element = document.getElementsByClassName("v-text-field__prefix");
        if (element) {
          const node1 = element[0];
          if (node1) $(node1).addClass("link");

          const node2 = element[1];
          if (node2) $(node2).addClass("link");
        }

        setTimeout(() => {
          // newthis.$store.state.isMobile = window.screen.width <= 480;
          newthis.$store.state.loaded = true;
        }, 50);
      };

      $(document).on("click", ".v-text-field__prefix", () => {
        this.$store.state.countriesModal = true;
      });
    },

    fetchPricing() {
      this.$store.state.pfetching = true;
      return Misc.getPricing()
        .then((x) => {
          this.$store.state.pricing = x.data;
          this.$store.state.pfetching = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.pfetching = false;
        });
    },

    fetchResources() {
      this.$store.state.rfetching = true;
      return Misc.getResources()
        .then((x) => {
          if (x.data) this.$store.state.resources = x.data;
          this.$store.state.rfetching = false;
        })
        .catch(() => {
          this.$store.state.rfetching = false;
        });
    },
  },

  watch: {
    "$vuetify.theme.dark"(n) {
      let color = "auto";
      let bgcolor = "white";
      if (n) {
        color = "#121212 #1e1e1e";
        bgcolor = "#1e1e1e";
      }
      document.getElementById("mhtml").style["scrollbar-color"] = color;
      document.getElementById("mhtml").style["background-color"] = bgcolor;

      localStorage.setItem("dark", n);
    },
  },

  data() {
    return {
      callingCodes: {},
    };
  },
};
