import $ from "jquery";
import config from "../../config";

import GetStarted from "../../components/get-started/get-started.vue";
import Apps from "../../components/apps/apps.vue";
import GrowcifyV2 from "../../components/growcify-v2/growcify-v2.vue";
import { isIndiaDomain } from "../../util/misc";
import NewsArticles from "../../components/news-articles/news-articles.vue";
import Prebooking from "../../components/prebooking/prebooking.vue";

export default {
  name: "Home",

  metaInfo() {
    const meta = this.getMeta;
    return {
      title: meta.title,
      meta: [
        { name: "description", content: meta.description },
        { property: "og:title", content: meta.title },
        { property: "og:description", content: meta.description },
      ],
    };
  },

  components: {
    Apps,
    GrowcifyV2,
    GetStarted,
    NewsArticles,
    Prebooking,
  },

  created() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  methods: {
    scheduleCall() {
      window.Calendly.initPopupWidget({ url: config.calendlyURL });
      return false;
    },

    open(type) {
      let url = config.webURL;
      if (type == "android") url = config.androidURL;
      else if (type == "ios") url = config.iosURL;
      else if (type == "dashboard") url = config.dashboardURL;
      else if (type == "examples") url = config.androidDeveloperURL;

      window.open(url, "_blank");
    },

    openAndroid(app) {
      const url = config.androidAppURL + app;
      window.open(url, "_blank");
    },

    openiOS(app) {
      const url = config.iosAppURL + app;
      window.open(url, "_blank");
    },

    navigate(url) {
      this.$router.push({ name: url }).catch(() => {});
    },

    playHow() {
      this.videoID = this.videoIDHowTo;
      this.watchVideo = true;
    },

    applyBPP() {
      const url = config.gbppURL;
      window.open(url, "_blank");
    },

    download(type) {
      const url = config[type + "URL"];
      window.open(url, "_blank");
    },

    playVideo() {
      window.open("https://www.youtube.com/watch?v=q2dJcHoCugM", "_blank");
    },
  },

  watch: {
    watchVideo(val) {
      /*stopping on close*/
      if (!val && this.$refs.playVideoPlayer) {
        this.videoID = this.videoIDAdFilm;
        const iframe = this.$refs.playVideoPlayer;
        let url = iframe.src;
        url = url.replace("&autoplay=1", "");
        this.$refs.playVideoPlayer.src = "";
        this.$refs.playVideoPlayer.src = url;
      } else {
        this.vloading = true;
        setTimeout(() => (this.vloading = false), 150);
      }
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },

    domain() {
      return this.$store.state.domain;
    },

    windowWidth() {
      return this.$store.state.ww;
    },

    loaded() {
      return this.$store.state.loaded;
    },

    getMeta() {
      let meta = this.metas.default;
      if (this.domain) {
        if (this.metas[this.domain]) {
          meta = this.metas[this.domain];
        }
      } else {
        const isIndia = isIndiaDomain();
        if (isIndia) meta = this.metas.in;
      }
      return meta;
    },

    hasWindow() {
      return window && window.innerWidth;
    },
  },

  data() {
    return {
      vloading: false,
      watchVideo: false,
      countryPicker: false,

      videoID: "q2dJcHoCugM",
      videoIDAdFilm: "q2dJcHoCugM",
      videoIDHowTo: "8k1EuKUCz_M",

      imgs: {
        i0: require("@/assets/imgs/growcify-default-banner.png"),
        i1: require("@/assets/imgs/growcify-ad-film.png"),
        i2: require("@/assets/imgs/growcify-mini-banner-1.png"),
        i3: require("@/assets/imgs/growcify-mini-banner-2.png"),

        i4: require("@/assets/imgs/growcify-rungta-banner.png"),
        i5: require("@/assets/imgs/growcify-fishstore-banner.png"),
        i6: require("@/assets/imgs/growcify-emzy-banner.png"),
        i7: require("@/assets/imgs/growcify-aasife-biryani-banner.png"),

        i8: require("@/assets/imgs/growcify-manage-banner.png"),
        i9: require("@/assets/imgs/sales-inventory-dashboard-banner.png"),
        i10: require("@/assets/imgs/growcify-sms-email-banner.png"),
        i11: require("@/assets/imgs/growcify-payment-gateway-banner.png"),
        i12: require("@/assets/imgs/growcify-no-fee-banner.png"),

        i13: require("@/assets/imgs/growcify-logistics-partner.png"),
        i14: require("@/assets/imgs/growcify-account-manager.png"),
        i15: require("@/assets/imgs/growcify-economical-pricing.png"),

        i16: require("@/assets/imgs/growcify-testimonials.png"),
        i17: require("@/assets/imgs/growcify-business-partner-program.png"),
      },

      eitems: [
        "Unlimited products",
        "Unlimited categories",
        "Unlimited customers",
        "Unlimited vendors",
        "Unlimited orders",
      ],

      ditems: [
        "Abandoned Cart",
        "Download or generate invoices",
        "Easy refunds to customer's Wallet",
        "Send payment links to customers",
        "Assign orders to delivery boys and more",
      ],

      pitems: [
        "Send push notifications",
        "Personilized SMS Header",
        "Personilized Email Communication",
        "Retarget abandoned cart",
      ],

      pgitems: [
        "Integrate payment gateway",
        "Send payment links and reminders",
        "Collect payment via payment links",
        "Have no payment gateway? No worries.",
      ],

      epitems: [
        "Auto scalable servers",
        "~99.99% system up-time",
        "State-of-the-art technology",
        "Processes millions of requests daily",
        "Fully no-code app, website and POS",
      ],

      metas: {
        ca: {
          title: "Best eCommerce Platform in Canada - Growcify eCommerce App",
          description:
            "eCommerce App: Build an eCommerce app with Growcify®. Completely no-code eCommerce platform in Canada for users to build their online business app in 24 hours.",
        },
        uk: {
          title:
            "Best eCommerce Platform in United Kingdom - Growcify eCommerce App",
          description:
            "eCommerce App: Build an eCommerce app with Growcify®. Completely no-code eCommerce platform in United Kingdom for users to build their online business app in 24 hours.",
        },
        us: {
          title:
            "Best eCommerce Platform in United States - Growcify eCommerce App",
          description:
            "eCommerce App: Build an eCommerce app with Growcify®. Completely no-code eCommerce platform in United States for users to build their online business app in 24 hours.",
        },
        in: {
          title: "Best eCommerce Platform in India - Growcify eCommerce App",
          description:
            "eCommerce App: Build an eCommerce app with Growcify®. Completely no-code eCommerce platform in India for users to build their online business app in 24 hours.",
        },
        default: {
          title: "Best eCommerce Platform - Growcify eCommerce App",
          description:
            "eCommerce App: Build an eCommerce app with Growcify®. Completely no-code eCommerce platform for users to build their online business app in 24 hours.",
        },
      },

      validationRules: {
        mobileRule: [(v) => (v && !isNaN(v)) || "Enter a valid number"],
      },
    };
  },
};
