import config from "../../config";

export default {
  methods: {
    openAndroid(app) {
      const url = config.androidAppURL + app;
      window.open(url, "_blank");
    },

    openiOS(app) {
      const url = config.iosAppURL + app;
      window.open(url, "_blank");
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isHome() {
      return this.$route.name == "home";
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      imgs: {
        i1: require("@/assets/imgs/growcify-rungta-banner.png"),
        i2: require("@/assets/imgs/growcify-fishstore-banner.png"),
        i3: require("@/assets/imgs/growcify-emzy-banner.png"),
        i4: require("@/assets/imgs/growcify-rufil-banner.png"),
      },
    };
  },
};
