import config from '../../config';

export default {
  methods: {
    signup() {
      const mobile = this.$store.state.mobile;
      const callingCode = this.$store.state.callingCode;

      if (!mobile) return this.$refs.form.validate();
      window.location = `${config.signupURL}?cc=${callingCode}&mobile=${mobile}`;
    },
  },

  props: [
    'btnText',
    'hideDetails',
    'btnColor'
  ],

  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      validationRules: {
        mobileRule: [
          v => (v && !isNaN(v)) || 'Enter a valid mobile number'
        ],
      }
    }
  }
}