const getDomain = () => {
  let url = window.location.hostname;
  url = url.replace(/(https?:\/\/)?(www.)?/i, "");

  url = url.split(".");
  url = url.slice(url.length - 2).join(".");

  if (url.indexOf("/") !== -1) {
    return url.split("/")[0];
  }

  return url;
};

const isIndiaDomain = () => {
  const domain = getDomain();
  return ["growcify.in", "growcify.com"].includes(domain);
};

export { getDomain, isIndiaDomain };
