import config from "../../config";

export default {
  name: "Header",

  methods: {
    navigate(name, ignoreNewTab = false) {
      if (ignoreNewTab) {
        this.$router.push({ name: name }).catch(() => {});
      } else {
        const routeData = this.$router.resolve({
          name: name,
        });
        window.open(routeData.href, "_blank");
      }

      this.menu = false;
    },

    open(type) {
      let url = "";
      if (type == "signin") url = config.signinURL;
      else if (type == "signup") url = config.signupURL;
      else if (type == "web") url = config.webURL;
      else if (type == "blog") url = config.blogURL;

      window.open(url, "_blank");
      this.menu = false;
    },

    scheduleCall() {
      window.Calendly.initPopupWidget({ url: config.calendlyURL });
      return false;
    },
  },

  created() {},

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      items: [
        {
          _id: 1,
          href: "website",
          title: "Website",
        },
        {
          _id: 1,
          href: "pos",
          title: "POS Software",
        },
        {
          _id: 3,
          href: "app",
          title: "App - Growcify V1",
        },
        {
          _id: 4,
          href: "app-v2",
          title: "App - Growcify V2",
        },
        {
          _id: 5,
          href: "dashboard",
          title: "Sales & Inventory Dashboard",
        },
      ],

      ritems: [
        {
          _id: 1,
          href: "blog",
          title: "Blog",
        },
        {
          _id: 2,
          href: "resources",
          title: "FAQ & Videos",
        },
      ],

      menu: false,
      menuModal: false,

      llogo: require("@/assets/imgs/growcify-logo.png"),
      dlogo: require("@/assets/imgs/growcify-logo-dark.png"),
    };
  },
};
