import config from '../../config';
import ContactForm from '../../components/contact-form/contact-form.vue';

export default {
  name: 'GetStartedII',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  components: {
    ContactForm
  },

  methods: {
    navigate(url) {
      this.$router.push({ name: url }).catch(() => {});
    },
  },

  computed: {
    year() {
      return new Date().getFullYear();
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      logo: require('@/assets/imgs/growcify-white.png'),
      owners: require('@/assets/imgs/owners2.png'),
      mowners: require('@/assets/imgs/mowners2.png'),

      cfaqs: config.cfaqs,

      meta: {
        title: 'Create your eCommerce app with Growcify',
        description: 'Growcify is the best no-code app development platform that helps you build and launch dynamic & responsive eCommerce apps without coding.'
      },

      paitems: [
        '0% Growcify Platform fee',
        'Your single-vendor Android app',
        'Your single-vendor iOS app',
        'Point of Sale (POS)',
        '1000 free SMS credits',
        '5 GB media storage space',
        'Unlimited orders',
        'Unlimited products',
        'Unlimited categories',
        'Unlimited customers',
        'Unlimited downloads',
        'Push notifications',
        '24/7 system and app up',
        'Abandoned cart',
        'Payment gateway integration',
        'Shipping & logistics integration',
        'Sales & Inventory Dashboard',
        'Up to 10 employees access',
        'Get your app within 24 hours',
        'Office hours support (9am - 6pm)',
        'Google Play & Apple App Store Listing',
      ],
    }
  }
}