import config from '../../config';

export default {
  name: 'Footer',

  methods: {
    goto(url) {
      window.open(url, '_blank');
    },

    openURL(url) {
      window.open(url, '_blank');
    },

    social(type) {
      let url = '';
      if (type == 'facebook') url = config.facebookURL;
      if (type == 'instagram') url = config.instagramURL;
      if (type == 'twitter') url = config.twitterURL;
      if (type == 'linkedin') url = config.linkedinURL;
      if (type == 'youtube') url = config.youtubeURL;

      window.open(url, '_blank')
    },

    navigate(name) {
      if (name == 'careers') return this.openURL(config.careerURL)
      return this.$router.push({ name: name }).catch(() => {});
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    year() {
      return new Date().getFullYear();
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      web: config.webURL,
      localites: 'https://localites.co',

      imgs: {
        i1: require('@/assets/imgs/footer-bg.png'),
        i2: require('@/assets/imgs/footer-dbg.png'),

        i3: require('@/assets/imgs/emergent-ventures-mercatus-center.png'),
        i4: require('@/assets/imgs/startup-partners.png'),
        i5: require('@/assets/imgs/payments-razorpay-stripe.png')
      }
    }
  }
}