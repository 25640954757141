import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: false,

  state: {
    mobile: "",
    callingCode: "91",
    countriesModal: false,

    pfetching: false,
    pricing: false,

    rfetching: false,
    resources: false,

    ww: window.innerWidth,
    isMobile: false,
    //navigator.userAgent &&
    ///iPhone|iPad|iPod|Android/i.test(navigator.userAgent),

    //isMobile: true,

    domain: "",
    loaded: false,
  },
});

export default store;
