import $ from "jquery";

import config from "../../config";

import Apps from "../../components/apps/apps.vue";
import GrowcifyV2 from "../../components/growcify-v2/growcify-v2.vue";
import GetStarted from "../../components/get-started/get-started.vue";

export default {
  name: "AppV2",

  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        { name: "description", content: this.meta.description },
        { property: "og:title", content: this.meta.title },
        { property: "og:description", content: this.meta.description },
      ],
    };
  },

  components: {
    Apps,
    GetStarted,
    GrowcifyV2,
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  created() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  methods: {
    open(type) {
      let url = config.webURL;
      if (type == "android") url = config.androidURLV2;
      else if (type == "ios") url = config.iosURLV2;

      window.open(url, "_blank");
    },
  },

  data() {
    return {
      imgs: {
        i1: require("@/assets/imgs/v2/growcify-app-banner-1.png"),
        i2: require("@/assets/imgs/play-store.png"),
        i3: require("@/assets/imgs/appstore.png"),
      },

      items: [
        {
          id: 1,
          title: "Onboarding Screens",
          description: "Create and set your onboarding screens",
          img: require("@/assets/imgs/v2/01.png"),
          alt: "eCommerce App Development - Onboarding Screens",
        },
        {
          id: 2,
          title: "Signup & Signin",
          description: "Easy sign up and sign in process",
          img: require("@/assets/imgs/v2/02.png"),
          alt: "eCommerce SaaS Platform - Signup",
        },
        {
          id: 3,
          title: "Location Screen",
          description: "Auto detect or manual user location",
          img: require("@/assets/imgs/v2/03.png"),
          alt: "Geofencing - eCommerce App",
        },
        {
          id: 4,
          title: "Home Screen",
          description: "Clean and modern home page",
          img: require("@/assets/imgs/v2/04.png"),
          alt: "Best Home Screen eCommerce",
        },
        {
          id: 5,
          title: "Category Screen",
          description: "Create categories & subcategories",
          img: require("@/assets/imgs/v2/5.png"),
          alt: "Categories UI Retail Apps",
        },
        {
          id: 6,
          title: "Product Screen",
          description: "Create products and manage variants",
          img: require("@/assets/imgs/v2/6.png"),
          alt: "Retail Business - Product Page",
        },
        {
          id: 7,
          title: "Cart Screen",
          description: "Manage your cart and place order",
          img: require("@/assets/imgs/v2/7.png"),
          alt: "Retail eCommerce App - Add to Cart",
        },
        {
          id: 8,
          title: "Account Screen",
          description: "Manage your account and wallet",
          img: require("@/assets/imgs/v2/8.png"),
          alt: "B2B eCommerce App - Account",
        },
        {
          id: 9,
          title: "Notifications Screen",
          description: "View all or your notifications",
          img: require("@/assets/imgs/v2/9.png"),
          alt: "Send Push Notifications",
        },
        {
          id: 10,
          title: "Dashboard Screen",
          description: "As the owner, access your dashboard",
          img: require("@/assets/imgs/v2/10.png"),
          alt: "Inventory Management Solution",
        },
        {
          id: 22,
          title: "Dashboard Screen",
          description: "Manage your dashboard, view stats",
          img: require("@/assets/imgs/v2/22.png"),
          alt: "Mobile App for Inventory Management",
        },
        {
          id: 11,
          title: "POS Screen",
          description: "Use mobile POS to create orders",
          img: require("@/assets/imgs/v2/11.png"),
          alt: "Point of Sale System for Offline Store",
        },
        {
          id: 12,
          title: "Barcode Screen",
          description: "Use barcode scanner to create orders",
          img: require("@/assets/imgs/v2/12.png"),
          alt: "Mobile POS with Barcode Scanner",
        },
        {
          id: 13,
          title: "POS Order Screen",
          description: "Switch between categories in the POS",
          img: require("@/assets/imgs/v2/13.png"),
          alt: "Create POS Order",
        },
        {
          id: 14,
          title: "Manage Products",
          description: "Create or manage your products",
          img: require("@/assets/imgs/v2/14.png"),
          alt: "Add and Manage Products Inventory",
        },
        {
          id: 15,
          title: "Manage Categories",
          description: "Create or manage your categories",
          img: require("@/assets/imgs/v2/15.png"),
          alt: "Categories - Inventory Solution",
        },
        {
          id: 16,
          title: "Manage Notifications",
          description: "Send or manage push notifications",
          img: require("@/assets/imgs/v2/16.png"),
          alt: "eCommerce App with Push Notifications",
        },
        {
          id: 17,
          title: "Manage Reports",
          description: "Create or manage reports",
          img: require("@/assets/imgs/v2/17.png"),
          alt: "Generate Sales Reports",
        },
        {
          id: 18,
          title: "Manage Settings",
          description: "Modify or manage your settings",
          img: require("@/assets/imgs/v2/18.png"),
          alt: "Manage Settings",
        },
        {
          id: 19,
          title: "Pickup & Drop",
          description: "Manage pickup & drop feature",
          img: require("@/assets/imgs/v2/19.png"),
          alt: "Pickup & Drop eCommerce App Development",
        },
        {
          id: 20,
          title: "Deliveryman Mode",
          description: "View pending or completed deliveries",
          img: require("@/assets/imgs/v2/20.png"),
          alt: "E-Commerce Delivery App",
        },
        {
          id: 21,
          title: "Trip Screen",
          description: "Start or end your delivery trip",
          img: require("@/assets/imgs/v2/21.png"),
          alt: "E-Commerce Delivery App for Retail Businesses",
        },
      ],

      meta: {
        title: "Launch your eCommerce app - Growcify",
        description:
          "Growcify is the best no-code app development platform that helps you build and launch dynamic & responsive eCommerce apps without coding.",
      },
    };
  },
};
