import $ from 'jquery';
import GetStarted from '../../components/get-started/get-started.vue';

export default {
  name: 'Dashboard',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  components: {
    GetStarted
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  created() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  data() {
    return {
      imgs: {
        i1: require('@/assets/imgs/growcify-dashboard-banner-1.png'),
        i2: require('@/assets/imgs/growcify-dashboard-banner-2.png'),
        i3: require('@/assets/imgs/growcify-dashboard-banner-3.png'),
        i4: require('@/assets/imgs/growcify-dashboard-banner-4.png'),
        i5: require('@/assets/imgs/growcify-dashboard-banner-5.png'),
        i6: require('@/assets/imgs/growcify-dashboard-banner-6.png'),
        i7: require('@/assets/imgs/growcify-dashboard-banner-7.png'),
        i8: require('@/assets/imgs/growcify-dashboard-banner-8.png'),
        i9: require('@/assets/imgs/growcify-dashboard-banner-9.png'),
      },

      pitems: [
        'Bulk upload products using excel file',
        'Add variants of products',
        'Mark product as new arrivals or trending',
        'Generate barcodes',
        'Auto update of stocks'
      ],

      ritems: [
        'Create flat discount coupons',
        'Create cashback coupons',
        'Create subscription coupons',
        'Create and export custom reports',
        'Generate reports in Excel, PDF or JSON formats'
      ],

      citems: [
        'Create new customers',
        'Edit or delete customers ',
        'View customer order, referral and address history ',
        'Manage customer memberships',
        'Add money to customer’s wallet'
      ],

      sitems: [
        'Create, edit or pause subscriptions',
        'Create weekly, monthly, quarterly or yearly subscriptions',
        'Download subscriptions invoices',
        'Assign deliveryman and more',
      ],

      oitems: [
        'Create, edit, accept orders',
        'Download, print invoices',
        'Assign deliverman to orders ',
        'Schedule shipment and more'
      ],

      witems: [
        'Create main and mini banners',
        'Create multiple home page groups',
        'Setup store title, color and meta tags for SEO',
        'Integrate any additional JS scripts and more'
      ],

      meta: {
        title: 'Manage your retail or restaurant business - Growcify',
        description: 'Growcisy\'s advanced sales and inventory dashboard helps you grow your business with easy management of orders, shipments, customer subscriptions, etc.'
      },
    }
  }
}