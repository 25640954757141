import $ from "jquery";

export default {
  name: "Contact",

  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        { name: "description", content: this.meta.description },
        { property: "og:title", content: this.meta.title },
        { property: "og:description", content: this.meta.description },
      ],
    };
  },

  created() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  methods: {
    navigate(url) {
      this.$router.replace({ name: url }).catch(() => {});
    },
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  data() {
    return {
      img: require("@/assets/imgs/404.png"),
      meta: {
        title: "Page Not Found - Growcify",
        description:
          "Thanks for visiting. Unfortunately, the page you're looking for is not found. Please go back to home. Thank you.",
      },
    };
  },
};
