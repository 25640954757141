import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "../pages/home/home";
import POS from "../pages/pos/pos";
import App from "../pages/app/app";
import AppV2 from "../pages/app-v2/app-v2";
import Contact from "../pages/contact/contact";
import Pricing from "../pages/pricing/pricing";
import Website from "../pages/website/website";
import Resources from "../pages/resources/resources";
import Dashboard from "../pages/dashboard/dashboard";
import GetStartedI from "../pages/get-started-i/get-started-i";
import GetStartedII from "../pages/get-started-ii/get-started-ii";

import PrivacyPolicy from "../pages/privacy-policy/privacy-policy";
import TermsAndConditions from "../pages/terms-and-conditions/terms-and-conditions";
import PathNotFound from "../pages/page-not-found/page-not-found";

const routes = [
  {
    path: "",
    name: "home",
    component: Home,
  },
  {
    path: "/pos-software",
    name: "pos",
    component: POS,
  },
  {
    path: "/ecommerce-app",
    name: "app",
    component: App,
  },
  {
    path: "/multivendor-ecommerce-app",
    name: "app-v2",
    component: AppV2,
  },
  {
    path: "/ecommerce-website",
    name: "website",
    component: Website,
  },
  {
    path: "/inventory-dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    path: "/blog/:slug?",
    name: "blog",
    component: POS,
  },
  {
    path: "/resources",
    name: "resources",
    component: Resources,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/get-started",
    name: "get-started",
    component: GetStartedI,
  },
  {
    path: "/get-started-ii",
    name: "get-started-ii",
    component: GetStartedII,
  },
  {
    path: "/get-started-iii",
    name: "get-started-iii",
    component: GetStartedII,
  },
  {
    path: "/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/app",
    redirect: "/ecommerce-app",
  },
  {
    path: "/v2",
    redirect: "/multivendor-ecommerce-app",
  },
  {
    path: "/website",
    redirect: "/ecommerce-website",
  },
  {
    path: "/pos",
    redirect: "/pos-software",
  },
  {
    path: "/dashboard",
    redirect: "/inventory-dashboard",
  },
  {
    path: "/page-not-found",
    name: "404",
    component: PathNotFound,
  },
  { path: "*", component: PathNotFound },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

export default router;
