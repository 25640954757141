import Vue from "vue";
import VueAxios from "vue-axios";
import VueMeta from "vue-meta";
import AOS from "aos";

import App from "./app.vue";
import vuetify from "./plugins/vuetify";

import http from "./http";
import router from "./router";
import store from "./store";

//custom imports
import "./assets/css/custom.scss";

Vue.config.productionTip = false;

Vue.filter("fc", function (value, currency = "INR") {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency,
  }).format(value);
});

Vue.filter("fcp", function (value, currency = "INR") {
  let amt = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency,
  }).format(value);

  amt = amt.split(".");
  return amt[0] + ".00";
});

//use default
Vue.use(VueMeta);
Vue.use(VueAxios, http);
Vue.config.productionTip = false;

vuetify.framework.theme.themes.light.primary = "#007bff";

new Vue({
  created() {
    AOS.init({
      delay: 100,
      duration: 500,
      once: true,
    });
  },
  store,
  vuetify,
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
