import config from '../../config';
import ContactForm from '../../components/contact-form/contact-form.vue';

export default {
  name: 'GetStartedI',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  components: {
    ContactForm
  },

  methods: {
    navigate(url) {
      this.$router.push({ name: url }).catch(() => {});
    },
  },

  computed: {
    year() {
      return new Date().getFullYear();
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      logo: require('@/assets/imgs/growcify-white.png'),
      owners: require('@/assets/imgs/owners.png'),
      mowners: require('@/assets/imgs/mowners.png'),

      cfaqs: config.cfaqs,

      meta: {
        title: 'Create your eCommerce app with Growcify',
        description: 'Growcify is the best no-code app development platform that helps you build and launch dynamic & responsive eCommerce apps without coding.'
      },

      paitems: [
        'All features are included',
        'Your Brand\'s Android App',
        'Your Brand\'s iOS App',
        'Your Brand\'s Website',
        'Your Brand\'s POS',
        'Access to the entire Growcify platform'
      ],
    }
  }
}