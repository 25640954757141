module.exports = {
  baseAPIDev: "http://localhost:9000/v1",
  baseAPI: "https://api.growcify.com/v1",

  webURL: "https://web.growcify.com",
  androidURL: "https://play.google.com/store/apps/details?id=co.grocify",
  iosURL: "https://apps.apple.com/app/id1534266201",
  androidURLV2: "https://play.google.com/store/apps/details?id=com.growcify",
  iosURLV2: "https://apps.apple.com/app/id6443738657",

  dashboardURL: "https://dashboard.growcify.com",
  androidDeveloperURL:
    "https://play.google.com/store/apps/dev?id=6776983213289151784",

  blogURL: "https://blog.growcify.com",

  signinURL: "https://dashboard.growcify.com/signin",
  signupURL: "https://dashboard.growcify.com/signup",

  androidAppURL: "https://play.google.com/store/apps/details?id=",
  iosAppURL: "https://apps.apple.com/app/",

  gbppURL:
    "https://docs.google.com/forms/d/e/1FAIpQLSdcFoRnMQ68a9ToJ3zeP14wszY6P55mqNMbqH7hidUwJVQU3Q/viewform",

  brochureURL: "https://growcify.com/files/Growcify Brochure.pdf",
  posBrochureURL: "https://growcify.com/files/Growcify POS.pdf",
  bppURL: "https://growcify.com/files/Growcify Business Partner Program.pdf",

  careerURL: "https://www.linkedin.com/company/growcify/jobs/",
  calendlyURL: "https://calendly.com/growcify/demo?primary_color=007bff",
  checkmarkAnimationJson:
    "https://assets3.lottiefiles.com/packages/lf20_pmYw5P.json",

  websiteSecret: "EAADLkRyHkW-cBAFInGissY4ZCztYY6Z-CbGai29fcZBzxkQZA0",

  facebookURL: "https://www.facebook.com/growcify",
  instagramURL: "https://www.instagram.com/growcify",
  twitterURL: "https://twitter.com/growcify",
  linkedinURL: "https://www.linkedin.com/company/growcify",
  youtubeURL: "https://www.youtube.com/channel/UCfKRzz6rHxeoPb_vTeQNCTg",

  ogImage: "https://growcify.com/images/social-preview.png",

  cfaqs: [
    {
      title: "What is Growcify?",
      description:
        "Growcify® is an eCommerce SaaS platform helping businesses go online with their own eCommerce app, website and POS with zero coding knowledge and within 24 hours.",
    },
    {
      title: "Can I cancel at any time?",
      description: "Yes, you can.",
    },
    {
      title: "If I decide to terminate, will I get refund?",
      description: "No. We have zero refund policy.",
    },
    {
      title: "Do I need to sign any contract or NDA?",
      description:
        "No. Growcify, being a SaaS platform, does not require you to sign any contract. Simply sign up and get started.",
    },
    {
      title: "Is my data secured?",
      description:
        "Yes, your data is fully secured and no one has access to it except you.",
    },
    {
      title: "Can I upgrade or downgrade my plan?",
      description:
        "Yes, you can upgrade or downgrade your plan except if you've purchased onetime plan.",
    },
    {
      title: "If there any platform fee or commission?",
      description:
        "No, the platform fee is only applicable in the 'Pay as you Grow' plan.",
    },
    {
      title: "Will I get source code?",
      description: "No. Being a SaaS platform, we do not provide source code.",
    },
    {
      title: "Is there any annual maintance charge applicable?",
      description:
        "AMC is only applicable in these cases: one-time purchase or pay as you grow.",
    },
    {
      title: "Will I get white-labbeled app?",
      description:
        "Yes, your apps are fully white-labelled depending on plan features.",
    },
    {
      title:
        "How many times I can request to change the logo before publishing?",
      description:
        "We allow 2 times for free. After that, it's paid for every request in the logo change. Also, the first logo uploaded by you is counted as 1st. So make sure to upload your final logo only.",
    },
    {
      title: "Can I change app name and logo after publishing?",
      description:
        "Not for free. Once the app is published, you can change the app name and logo by paying a fee.",
    },
    {
      title: "Will I get domain from Growcify?",
      description: "No. You will need to purchase a domain name registrar.",
    },
  ],
};
