import $ from 'jquery';

import config from '../../config';

export default {
  name: 'Resources',

  metaInfo() {
    return { 
      title: this.meta.title,
      meta: [
        { name: 'description', content: this.meta.description },
        { property: 'og:title', content: this.meta.title },
        { property: 'og:description', content: this.meta.description },
      ]
    }
  },

  created() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  },

  computed: {
    rfetching() {
      return this.$store.state.rfetching;
    },

    resources() {
      return this.$store.state.resources;
    },

    allfaqs() {
      return this.cfaqs.concat(this.faqs);
    },

    faqs() {
      return this.resources ? this.$store.state.resources.faqs : [];
    },

    videos() {
      return this.resources ? this.$store.state.resources.videos : [];
    },

    isDT() {
      return this.$vuetify.theme.dark;
    },

    darkDullColor() {
      return this.isDT ? 'grey darken-3' : 'grey lighten-3'
    },

    isMobile() {
      return this.$store.state.isMobile;
    }
  },

  data() {
    return {
      cfaqs: config.cfaqs,
      meta: {
        title: 'Growcify - Resources',
        description: 'Have queries about Growcify? Check this page to find the most frequently asked questions of Growcify to resolve your doubts. Have more? schedule a call.'
      }
    }
  }
}