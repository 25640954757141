import $ from "jquery";

import config from "../../config";

import Apps from "../../components/apps/apps.vue";
import GrowcifyV2 from "../../components/growcify-v2/growcify-v2.vue";
import GetStarted from "../../components/get-started/get-started.vue";

export default {
  name: "POS",

  metaInfo() {
    return {
      title: this.meta.title,
      meta: [
        { name: "description", content: this.meta.description },
        { property: "og:title", content: this.meta.title },
        { property: "og:description", content: this.meta.description },
      ],
    };
  },

  components: {
    Apps,
    GetStarted,
    GrowcifyV2,
  },

  computed: {
    isDT() {
      return this.$vuetify.theme.dark;
    },

    isMobile() {
      return this.$store.state.isMobile;
    },
  },

  created() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  },

  methods: {
    open(type) {
      let url = config.webURL;
      if (type == "android") url = config.androidURL;
      else if (type == "ios") url = config.iosURL;

      window.open(url, "_blank");
    },
  },

  data() {
    return {
      imgs: {
        i1: require("@/assets/imgs/growcify-app-banner-1.png"),
        i2: require("@/assets/imgs/play-store.png"),
        i3: require("@/assets/imgs/appstore.png"),
        i4: require("@/assets/imgs/growcify-app-banner-2.png"),
        i5: require("@/assets/imgs/growcify-app-banner-3.png"),
        i6: require("@/assets/imgs/growcify-app-banner-4.png"),
        i7: require("@/assets/imgs/growcify-app-banner-5.png"),
        i8: require("@/assets/imgs/growcify-app-banner-6.png"),
        i9: require("@/assets/imgs/growcify-app-banner-7.png"),
        i10: require("@/assets/imgs/growcify-app-banner-8.png"),
        i11: require("@/assets/imgs/growcify-app-banner-9.png"),
      },

      meta: {
        title: "Launch your eCommerce app - Growcify",
        description:
          "Growcify is the best no-code app development platform that helps you build and launch dynamic & responsive eCommerce apps without coding.",
      },
    };
  },
};
