<template>
  <v-dialog v-model="$store.state.countriesModal" max-width="375">
    <v-progress-linear v-if="cloading" indeterminate></v-progress-linear>
    <v-card v-else class="pt-8 pb-8 pl-8 pr-8">
      <v-row class="animate__animated animate__fadeIn">
        <v-col class="vc">
          <h3>Select your country</h3>
          <small>Pick your country to continue</small>
        </v-col>

        <v-col cols="3" class="vc text-right">
          <v-btn fab outlined small @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="vc">
          <v-text-field
            required
            v-model="csearch"
            single-line
            hide-details
            placeholder="Search..."
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="vc animate__animated animate__fadeIn">
          <v-card
            link
            v-for="(item, index) in countries"
            :key="index"
            outlined
            class="pt-5 pb-5 pl-6 pr-6 mb-3"
            @click="countrySelected(item)"
          >
            <h4>(+{{ item.callingCode }}) {{ item.name }}</h4>
          </v-card>

          <div v-if="!countries.length" class="pt-2 text-center">
            <span>No countries found!</span>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import Misc from "../../services/misc";

export default {
  name: "Countries",

  props: ["callingCode"],

  watch: {
    csearch(n) {
      if (n)
        return (this.countries = this.allCountries.filter(
          (x) => x.name.toLowerCase().indexOf(n) != -1
        ));
      this.countries = this.allCountries;
    },

    "$store.state.countriesModal"() {
      if (!this.countries.length) this.fetchCountries();
    },
  },

  methods: {
    countrySelected(item) {
      this.$store.state.callingCode = item.callingCode;
      this.$store.state.countriesModal = false;
    },

    close() {
      this.$store.state.countriesModal = false;
    },

    fetchCountries() {
      this.cloading = true;
      return Misc.getCountries()
        .then((x) => {
          if (x.data) {
            this.countries = x.data;
            this.allCountries = x.data;
          }
          this.cloading = false;
        })
        .catch(() => (this.cloading = false));
    },
  },

  data() {
    return {
      cloading: false,
      csearch: "",

      countries: [],
      allCountries: [],
    };
  },
};
</script>
